import { MenuService } from "../menu.service";


const actions = {
    open({ commit }){
        commit('open');
    },
    activate({ commit }){
        commit('activate');
    },
    activeItem({ commit }, item) {
        commit('setActiveItem', item);
    },
    navWrapperLevelDown({ commit }) {
        commit('setIsLevelDown');
    },
    toggleStackNav({ commit }) {
        commit('setExpand');
    },
    tabHidden({ commit }) {
        commit('setTabHidden');
    },
    mobileBackClick({ commit }) {
        commit('mobileBack')
    },
    isRenderSublist({ commit }, model) {
        commit('setRenderSublist', model);
        
    },
    uiUpdateLoading({ commit }, model) {
        commit('setUiUpdateLoading', model);
    },
    async getNavData({ state, commit }) {
        const menuService = new MenuService(state.jsonUrl, state.fallbackError, state.selectedMenuId);
        const datas = await menuService.handle(commit);        
        commit('setNavData', datas);
    }
};

export default actions;