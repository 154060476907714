import App from "./src/App.vue";
import store from './src/store';

const { createApp }  = Vue;

(function(window) {
	let _init = ({ config = {}, container = '' }) => {
		const el = document.getElementById(container);
		if (el) {
			console.log('LMUcast Initialised');
			createApp(App)
				.use(store(config))
				.mount(el);			
		} else {
			console.error('LMUcast Initialised failed!');
		}
	};

	window.LMUcast = {
		init: _init
	};
})(window);
