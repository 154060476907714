const { createApp }  = Vue;

import App from "./src/App.vue";

(function (window) {
    
    function _init({config = {}, container = "open-access-publication"}) {

        const app = createApp(App)
        app.config.globalProperties.rootData = config; 
        app.mount("#" + container);
    }

    window.LmuOpenAccessPublications= {
        init : _init

    }

})(window);
