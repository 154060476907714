<template>
    <ul class="anchor-nav__list">        
        <li v-for="(groupItem, index) in groupItems" class="anchor-nav__list-item" :key="groupItem.uri">
            <a class="anchor-nav__link" v-bind:href="'#group_' + groupItem">{{ getDisplayTitle(groupItem, language, doctypes) }}</a>
            <template v-if="index != groupItems.length - 1"> </template>
        </li>
    </ul>
</template>


<script>
import useDataMapper from '../composables/useDataMapper';

export default {
    props: ["groupItems", "language", "doctypes"],
    setup() {
        const { getDisplayTitle } = useDataMapper()
        return { getDisplayTitle }
    }
}
</script>