<template>
	<div class="open-access-publications__loader">
        <span class="open-access-publications__init-loader">
            <span class="open-access-publications__init-loader-box is-one"></span>
            <span class="open-access-publications__init-loader-box is-two"></span>
            <span class="open-access-publications__init-loader-box is-three"></span>
        </span>
    </div>
</template>    

<script>
export default {
    name: "Loader"
}
</script>