<template>
  <ul class="ml-stack-nav__menu">
    <NavListParentItem
      v-for="(item, index) in list"
      :key="index"
      :item="item"
    />
    <li class="fragment-li">
      <h4
        v-if="lngHeadline()"
        class="ml-stack-nav__sub-headline"
        :class="$store.state.lngVersions.settings.navigationClasses"
        v-text="$store.state.lngVersions.content.headline"
      ></h4>
    </li>
    <NavLngSwitch
      v-for="(lng, index2) in lngList()"
      :key="'sw_' + index2"
      :lng="lng"
    />
  </ul>
</template>

<script>
import NavLngSwitch from "./NavLngSwitch.vue";
import NavListParentItem from "./NavListParentItem.vue";

export default {
  name: "NavList",
  props: ["list"],
  components: {
    NavListParentItem,
    NavLngSwitch,
  },
  mounted() {},
  methods: {
    lngList() {
      return this.$store.state.lngVersions === undefined
        ? []
        : this.$store.state.lngVersions.content.items;
    },
    lngHeadline() {
      if (this.$store.state.lngVersions) {
        return true;
      }

      return false;
    },
  },
};
</script>
