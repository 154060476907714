<template>
  <ul class="ml-stack-nav__menu ml-stack-nav-p">
    <li class="fragment-li">
      <h4
        v-if="item.content.sublist.content.headline"
        class="ml-stack-nav__sub-headline"
        :class="item.content.sublist.settings.navigationClasses"
        v-text="item.content.sublist.content.headline"
      ></h4>
    </li>
    <NavListItem
      v-for="(item, index) in item.content.sublist.content.items"
      :key="index"
      :item="item"
      :index="index"
    />
    <li class="fragment-li">
      <h4
        v-if="lngHeadline()"
        class="ml-stack-nav__sub-headline"
        :class="$store.state.lngVersions.settings.navigationClasses"
        v-text="$store.state.lngVersions.content.headline"
      ></h4>
    </li>
    <NavLngSwitch
      v-for="(lng, index2) in lngList()"
      :key="'sw_' + index2"
      :lng="lng"
    />
    <li class="ml-stack-nav__item">
      <button
        v-on:click="onBackClick"
        class="ml-stack-nav__back"
        type="button"
        title="Go to the previous level"
        v-text="$store.state.data.lngtxt.backLink"></button>
      <span class="is-aural" v-text="$store.state.data.lngtxt.backTxt"></span>
    </li>
  </ul>
</template>

<script>

import { defineAsyncComponent } from "vue";

export default {
  name: "NavSubList",
  props: ["item", "link"],
  components: {
    NavListItem: defineAsyncComponent(() => import("./NavListItem.vue")),
    NavLngSwitch: defineAsyncComponent(() => import("./NavLngSwitch.vue")),
  },  
  async updated(){    
    await this.$nextTick();
    this.$store.dispatch('uiUpdateLoading', false);
  },
  methods: {
    onBackClick() {
      if (this.item.content.link) {
        this.item.content.link.settings.aria = false;
        this.item.content.link.settings.isOpen = false;
        this.item.content.link.settings.isActive = false;

        if (this.item.parent.isRoot) {
          this.$store.dispatch("activate");
        } else {
          this.item.parent.content.link.settings.isActive = true;
        }

        this.$store.dispatch("navWrapperLevelDown");
        this.$store.dispatch(
          "activeItem",
          this.item.parent.isRoot === true ? null : this.item.parent
        );
      }
    },
    lngList() {
      return this.$store.state.lngVersions === undefined
        ? []
        : this.$store.state.lngVersions.content.items;
    },
    lngHeadline() {
      if (this.$store.state.lngVersions) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style>
</style>
