//useDataMapper.js

export default function useDataMapper() {

    // retrieves the associated displayTitle for the doctype based on all doctypes.
    function getDisplayTitle(doctypeId, language, doctypes) {

        let displayTitle = doctypes.find(x => x.id === doctypeId);
        if (typeof displayTitle !== 'undefined') {
            if (language == "en") {
                return displayTitle.en;
            } else {
                return displayTitle.de;
            }
        }
        return doctypeId;
    }
    return {
        getDisplayTitle
    }
}