<template>
   <ul class="ml-stack-nav__menu">
      <li class="ml-stack-nav__item">  
        <div class="ml-stack-nav__error">
          <div class="grid__container">
            <div class="grid__row">
              <div class="ml-stack-nav__error content" >
                  <h2 class="ml-stack-nav__error content headline" >
                    <span data-nosnippet="true" v-text="$store.state.fallbackError.title"></span>
                  </h2>
                  <div data-nosnippet="true" class="ml-stack-nav__error content description" v-text="$store.state.fallbackError.text"></div>
              </div>
            </div>
          </div>
        </div>
      </li>
   </ul>
</template>

<script>
export default {
	name: "LogError"
};
</script>
