import { getFeed } from '../rss.service';
const { createStore } = Vuex;

export default config =>
	createStore({
		state: {			
			isLoading: false,
            isSuccessful: false,
            data: [],	
            rssUrl: config.rssUrl,
            clipUrlPrefix: config.clipUrlPrefix,		
			error: null,
			showDownload: config.showDownload || false,
			showAudio: config.showAudio || false,
			texts: config.texts || {},
			format: config.format || 'de-DE',
			options: config.options || { year: 'numeric', month: '2-digit', day: '2-digit' },
			cssPostfix: config.cssPostfix || 'default',
		},
		actions: {
			async getPlaylist({ state, commit }) {
				commit('setIsLoading', true);
                const data = await getFeed(state.rssUrl, state.clipUrlPrefix).catch(e => {throw new Error(e.message)});
				commit('setData', data);
                commit('setIsLoading', false);
                commit('setIsSuccessful', true);
			},
			setError({ commit }, error) {
				commit('setError', error);
			},
			isLoading({ commit }, isLoading) {
				commit('setIsLoading', isLoading);
			}
		
		},
		getters: {
			getData(state) {
				return state.data;
			},
            getSuccessful(state) {
                return state.isSuccessful;
            },
			getError(state) {
				return state.error;
			},
			getShowDownload(state) {
				return state.showDownload;
			},
			getShowAudio(state) {
				return state.showAudio;
			}
		},
		mutations: {
			setData(state, model) {				
                state.data = model;
			},
            setIsLoading(state, isLoading) {
                state.isLoading = isLoading;
            },
            setIsSuccessful(state, isSuccessful) {
                state.isSuccessful = isSuccessful;
            },
			setError(state, error) {
				state.error = error;
			}

		}
	});
