<template>
    <div class="filterable-list__list-item-image">
            <!-- ListItem Picture -->
            <picture class="c-picture--default lazyload" data-css="c-picture">
              <img class="picture__image lazyload"  
                :src="src"
                :alt="alt"
                :title="title">
            </picture>
            <!-- ListItem Default Picture -->        
        </div>
</template>

<script>
    export default {
        name: "PlaylistItemPicture",
        props: ['src', 'title', 'alt']
    }
</script>
