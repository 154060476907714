<template>
  <div class="ml-stack-nav__link-content">
    <span class="ml-stack-nav__link-text" v-text="item.content.linkText"></span>
    <span
      v-if="item.content.linkLang"
      class="ml-stack-navlink-text-a11y"
      :lang="item.content.linkLang"
      v-text="item.content.linkTextA11y"
    ></span>
    <span
      v-else-if="hasSublist()"
      class="ml-stack-nav__link-subhint"
      :class="{ 'is-hidden': scount === 0 }"
    >
      <span class="ml-stack-nav__link-subhint-text" v-text="scount"></span>
      <span class="is-aural visually-hidden" v-text="$store.state.data.lngtxt.subListInfo"></span>
    </span>
  </div>
</template>

<script>
export default {
  name: "NavLinkContent",
  props: ["item", "scount"],
  methods: {
    hasSublist() {
      return this.scount > 1;
    },
  },
};
</script>

<style>
</style>
