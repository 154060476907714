import actions from "./actions";
import mutations from "./mutations";

const { createStore } = Vuex;

export default config =>
	createStore({
		state() {
			return {
				data: null,
			selectedMenuId: config.menuId,
			isLevelDown: false,
			isToggleStackNav: false,
			isTabHidden: false,
			lngVersions: config.lngVersions,
			currSubListLevel: 0,
			activeItem: null,
			fallbackError: config.fallbackError,
			resultsUrl: config.resultsUrl,
			isOpen: false,
			isActive: false,
			isRenderSublist: false,
			jsonUrl: config.url,
			uiUpdateLoading: false
		}
		},
		actions,
		mutations,
		getters: {
			getNavigationData(state){
				return state.data;
			}
		}
	});
