import { each } from "lodash";

export class MenuService {

    constructor(jsonUrl, fallbackError, selectedMenuId) {
        this._axiosSettings = { withCredentials: false, headers: { Accept: 'application/json', 'Content-Type': 'application/json' } }
        this._jsonUrl = jsonUrl;
        this._fallbackError = fallbackError;
        this._selectedMenuId = selectedMenuId;
    }


    async handle(commit) {
        const datas = await this.fetchNavigationData(this._jsonUrl).catch((err) => {
            console.error("Fettching navigation data has error: ", err);
            commit('logError', this._fallbackError);
            return null;
        });

        if (!datas) {
            return null;
        }

        this.buildMenuTree(datas.navigationLists, datas.navigationLists, true);

        const menuId = Number(this._selectedMenuId);
        if (!isNaN(menuId)) {
            console.info("Selected menu id: ", menuId);
            commit('setRenderSublist', (menuId !== 0));
            this.findMenuItem(datas.navigationLists, menuId, (target) => {                
                if (target.parent.content.link) {
                    target.parent.content.link.settings.aria = true;
                    if (!this.hasLinkOverview(target)) {
                        target.parent.content.link.settings.isActive = true;                        
                    }
                }
                target.content.link.settings.aria = true;
                target.content.link.settings.isOpen = true;
                target.content.link.settings.isActive = true;
                target.content.link.settings.linkClasses = 'is-active';
                this.updateParentAria(target, menuId);
            });
        }

        return datas;
    }

    

    async fetchNavigationData(url) {
        const http = axios.create(this._axiosSettings);
        const response = await http.get(url);
        return response.data;
    }


    buildMenuTree = (tree, parentObject, isRoot) => {
        const self = this;
        each(tree, function (item) {
            item.parent = parentObject;
            item.isRoot = isRoot;
            if (item.content.items) {
                self.buildMenuTree(item.content.items, item, false);
            }
            if (item.content.sublist) {
                item.content.link.settings.aria = false;
                item.content.link.settings.isOpen = false;
                item.content.link.settings.isActive = false;
                self.buildMenuTree(item.content.sublist.content.items, item, false);
            }
        });
    }

    findMenuItem = (container, id, callback) => {
        const self = this;
        id = String(id);
        each(container, function (item) {
            try {
                if (item.content.link) {
                    if (item.content.link.content.linkID === id) {
                        callback(item);
                    }
                }

                if (item.content.linkID) {
                    if (item.content.linkID === id) {
                        callback(item);
                    }
                }

                if (item.content.items) {
                    self.findMenuItem(item.content.items, id, callback);
                }

                if (item.content.sublist) {
                    self.findMenuItem(item.content.sublist.content.items, id, callback);
                }
            } catch (e) {
                console.log(e);
            }
        });
    };

    updateParentAria = (target) => {
        if (target.parent) {
            if (target.parent.content) {
                if (target.parent.content.link) {                    
                    target.parent.content.link.settings.aria = true;
                    target.parent.content.link.settings.isOpen = true;                    
                } 
                return this.updateParentAria(target.parent);
            }
        }
        return;
    };

    hasLinkOverview(target) {        
        if (!target.content.sublist) {
            return false;
        }

        let items = [...target.content.sublist.content.items];        
        return items.find(item => item.content.link.content.linkOverview) !== undefined;
    }

}



