<template>
    <li class="filterable-list__list-item">
        <PlaylistItemPicture :src="item.posterUrl" :alt="item.title" :title="item.title" />
        <PlaylistItemContent :item="item" />
    </li>
</template>

<script>
import PlaylistItemPicture from "./PlaylistItemPicture.vue";
import PlaylistItemContent from "./PlaylistItemContent.vue";
export default {
    name: "PlaylistItem",
    props: ["item"],
    components: {
        PlaylistItemPicture,
        PlaylistItemContent
    }
}

</script>