<template>
	<span class="filterable-list__load-init-loader">
		<span class="filterable-list__load-init-loader-box is-one"></span>
		<span class="filterable-list__load-init-loader-box is-two"></span>
		<span class="filterable-list__load-init-loader-box is-three"></span>
	</span>
</template>


<script>
export default {
	name: 'NewsLoader'
}
</script>
