<template>
    <div class="filterable-list__list-item-text-wrapper" >
        <em class="filterable-list__list-item-meta lmucast-playlist__list-item-meta">
            <span>
                {{formatPubDate(item.pubDate)}}
            </span>            
        </em>
        <span class="lmucast-playlist__list-item-title">{{ item.title }}</span>
        <p class="lmucast-playlist__list-item-subtitle">{{ item.subtitle }}</p>
        <PlaylistItemLinks :video-url="item.videoUrl" :audio-url="item.audioUrl" />
        <div class="lmucast-playlist__list-item-description-wrapper">
            <span class="lmucast-playlist__list-item-description-headline">{{ $store.state.texts?.authorTitle }}:</span>
            <span class="lmucast-playlist__list-item-description-speaker">{{ item.author }}</span>
            <p class="lmucast-playlist__list-item-description-text">
                {{ item.description }}
            </p>
        </div>
    </div>      
</template>

<script>
import PlaylistItemLinks from "./PlaylistItemLinks.vue";
export default {
    name: "PlaylistItemContent",
    props: ['item'],
    components: {
        PlaylistItemLinks
    },
    methods: {
        formatPubDate: function(pubDate)  {            
            let date = new Date(pubDate);
            return date.toLocaleDateString(this.$store.state.format, this.$store.state.options);
        }
    }
}
</script>