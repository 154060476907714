<template>
    <span class="g-font-bold">
        <a v-bind:href="currentItem.uri" target="_blank">{{ currentItem.title[0].name }}<template v-if="currentItem.titlealternative">.
                {{ currentItem.titlealternative[0].name }}</template></a><br>
    </span>
</template>


<script>

export default {
    props: ["currentItem"],
}

</script>