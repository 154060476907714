<template>    
    <div class="filterable-list__list-wrapper">            
        <ul class="filterable-list__list">
            <PlaylistItem v-for="(item) in playList" :key="item.id" :item="item"/>            
        </ul>
        <div class="filterable-list__load-more-wrapper"></div>            
    </div>    
</template>
<script>
    import PlaylistItem from "./PlayListItem.vue";
    export default {
        name: "Playlist",        
        components: {
            PlaylistItem
        },
        computed: {
            playList() {                
               const items = this.$store.getters.getData;
               if(items && items.length === 0) {
                    this.$store.dispatch('setError', 'No data');
                    return [];
               } else {
                   return items
               }            
            }
        }
    }

</script>