<template>
    <li class="fragment-li">
		<ul class="fragment-ul">
			<h4 class="ml-stack-nav__sub-headline" :class="item.settings.navigationClasses">{{item.content.headline}}</h4>
			<NavListItem v-for="(item, index) in item.content.items" :item="item" :key="index" />
		</ul>
    </li>
</template>

<script>
import NavListItem from "./NavListItem.vue"

export default {
	name: "NavListParentItem",
	props: ["item"],
	components: {
		NavListItem
	},
	computed: {
		subListCount() {
			if (this.item.content.items) {
				return this.item.content.items.length;
			}

			return 0;
		}
	},
	methods: {}
};
</script>
