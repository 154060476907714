<template>
  <button type="button" data-js-item="js-skip-links-navvuebutton" class="main-navigation__list-link is-burger" :class="isActive()"
   v-on:click="toggleStackNav()" >
	    <span class="main-navigation__list-link-text">{{opencloseNavText()}}</span>
    </button>
</template>

<script>
export default {
    name: "HamburgerMenuApp",
    methods: {
        toggleStackNav() {
            this.$store.dispatch("toggleStackNav");
            this.$store.dispatch('open');

            if (!this.$store.state.activeItem) {
                this.$store.dispatch('activate');
            }

        },
        isActive() {
            return this.$store.state.isToggleStackNav ? 'is-active' : '';
        },
        opencloseNavText() {
			return this.$store.state.data == null ? "" : this.$store.state.data.lngtxt.opencloseNav;
        }
    }
}
</script>