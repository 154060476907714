<template>
  <nav class="c-mobile-nav-toggler" :class="isMulti()">
    <ul class="mobile-nav-toggler__list">
      <li class="mobile-nav-togler__list-item is-back">
        <button class="mobile-nav-toggler__list-link is-back" v-on:click="backLevel()">
          <span class="mobile-nav-toggler__link-content is-back">
            <span class="mobile-nav-toggler__link-extra is-back"></span>
          </span>
          <span class="mobile-nav-toggler__link-text is-back">
            {{backBtnText()}}
            <span class="mobile-nav-toggler__link-text-aural is-aural">{{backTxt()}}</span>
          </span>
        </button>
      </li>
      <li class="mobile-nav-togler__list-item is-burger">
        <button
          class="mobile-nav-toggler__list-link is-burger"
          :class="isActive()"
          v-on:click="toggleStackNav()"
        >
          <span class="mobile-nav-toggler__link-content is-burger">
            <span class="mobile-nav-toggler__link-extra is-burger"></span>
          </span>
          <span class="mobile-nav-toggler__link-text is-burger">
            {{menuText()}}
            <span class="mobile-nav-toggler__link-text-aural is-aural">{{openText()}}</span>
          </span>
          <span class="mobile-nav-toggler__link-text-close is-burger">
            <span class="mobile-nav-toggler__link-text-close-aural is-aural">{{menuText()}}</span>
            {{closeText()}}
          </span>
        </button>
      </li>
    </ul>
  </nav>

</template>

<script>
export default {
	name: "MobileNav",
	data: function() {
		return {
			isOpen: false
		};
	},
	methods: {
    backLevel(){
      this.$store.dispatch('mobileBackClick');
      this.$store.dispatch('activeItem', this.$store.state.activeItem.parent.isRoot === true ? null : this.$store.state.activeItem.parent);
    },
    openAndActivate() {
      this.$store.dispatch('open');            
      this.$store.dispatch('activate');
    },
		toggleStackNav() {
			this.$store.dispatch("toggleStackNav");
			if (this.isOpen) {
        // Wait transation event before hide whole element.
				setTimeout(() => {
          this.openAndActivate();
          this.isOpen = false;
				}, 400);
			}else{        
        this.openAndActivate();
        this.isOpen = true;
      }
		},
		isActive() {
			return this.$store.state.isToggleStackNav ? "is-active" : "";
    },
    isMulti() {
       return (this.$store.state.activeItem === null || !this.$store.state.isToggleStackNav) ? ''  : 'is-multi';
    },
    backBtnText() {      
      return this.$store.state.data === null  ? '' : this.$store.state.data.lngtxt.backLink;
    },
    backTxt() {
      return this.$store.state.data === null  ? '' : this.$store.state.data.lngtxt.backTxt;
    },
    menuText() {
      return this.$store.state.data === null  ? '' : this.$store.state.data.lngtxt.menuText;
    },    
    openText() {
      return this.$store.state.data === null  ? '' : this.$store.state.data.lngtxt.openText;
    },
    closeText() {
      return this.$store.state.data === null  ? '' : this.$store.state.data.lngtxt.closeText;
    }
	}
};
</script>
