import axios from 'axios';

export const loadData = async (url) => {
    return axios.get(url);
}

export const getFeed = async (url, clipUrlPrefix, parser = domParser, selector = querySelector) => {
    const response = await loadData(url).catch(e => {throw new Error(e.message)}); 
    const data = await response.data;
    const playlist = parseFeed(data, clipUrlPrefix, parser, selector);
    return playlist;
}

const domParser = (data) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(data, "text/xml");
    const items = xmlDoc.getElementsByTagName("item");

    return items;
}


const parseFeed = (data, clipUrlPrefix, domParser, selector) => {
    const items = domParser(data);
    const playlist = [];
    for (let item of items) {
        let title = extractValue(item, "title", selector);
        let link = extractValue(item, "link", selector); 
        let subtitle = extractValue(item, "subtitle", selector); 
        let author = extractValue(item, "author", selector); 
        let pubDate = extractValue(item, "pubDate", selector); 
        let description = extractValue(item, "description", selector); 
        // extract ClipId from RSS link in order to create posterUrl, audioUrl and videoUrl
        let clipId = "";
        let posterUrl = "";
        let audioUrl = "";
        let videoUrl = "";
        if (link.length > 0) {
            clipId = getClipId(link);
            posterUrl = clipUrlPrefix  + clipId + '/file/custom_poster';
            audioUrl  =  clipUrlPrefix  + clipId + '/vod/audio_only.mp3'; 
            videoUrl  = clipUrlPrefix + clipId + '/vod/high_quality.mp4'; // apple potcast
        }

        let playItem = {
            id: clipId,
            title,   
            link,
            subtitle,
            author,
            pubDate,
            description,
            posterUrl,
            audioUrl,
            videoUrl
        }
        
        playlist.push(playItem);
    }

    return playlist;
}

const getClipId = (clipUrl) => {
    return clipUrl.split("/")[4];
};

const extractValue = (item, tagName, selector = querySelector) => {
    if (selector(item, tagName) === null) {
        return ""
    } else {        
        let node = selector(item, tagName).childNodes[0];
        if (typeof node !== 'undefined') {
            return node.nodeValue;
        } else {
            return ""
        }
    }
}

const querySelector = (item, tagName) => {
    return item.querySelector(tagName);
}

