<template>       
    <div :class="cssPostfix()" data-css="c-filterable-list">                
        <form class="c-form--filterable-list" :class="[isLoading(), isSuccess()]"  data-css="c-form">          
            <NewsLoader />
            <Playlist v-if="isCompleted" />
            <PlaylistError v-if="$store.getters.getError" />
        </form>            
    </div>
</template>

<script>
import NewsLoader from "../../../newsroom-app/app/src/components/Loader.vue";
import Playlist from "./components/Playlist.vue";
import PlaylistError from "./components/PlaylistError.vue";
export default {
    name: "LMUcastApp",
    components: {
        NewsLoader,
        Playlist,
        PlaylistError
    },
    data() {
        return {
            error: this.$store.getters.getError
        }
    },
    async created() {
        try {
            await this.$store.dispatch('getPlaylist');
        }
        catch (e) {
            this.error = e.message;
            await this.$store.dispatch('isLoading', false);
        }
    },
    computed: {
        isCompleted() {
            return this.$store.state.isSuccessful;
        },
    },
    methods: {
        isLoading() {
            return this.$store.state.isLoading ? "is-loading" : "";
        },
        isSuccess() {
            return this.$store.state.isSuccessful ? "is-success" : "";
        },
        cssPostfix() {
            return `c-filterable-list--${this.$store.state.cssPostfix}`;
        }
    },
}

</script>