<template>
    <div class="lmucast-playlist__list-item-links">
            <a class="lmucast-playlist__list-item-links-link is-icon is-video" 
                target="_blank"
                :href="videoUrl">{{ $store.state.texts?.links?.videoLink }}</a>
            <a  v-if="$store.state.showDownload"
                class="lmucast-playlist__list-item-links-link is-icon is-download" 
                target="_blank"
                :href="videoUrl">{{ $store.state.texts?.links?.downloadLink }}</a>
            <a  v-if="$store.state.showAudio"
                class="lmucast-playlist__list-item-links-link is-icon is-audio" 
                target="_blank"
                :href="audioUrl">
                {{ $store.state.texts?.links?.audioLink }}
            </a>
        </div>                                   
</template>

<script>
    export default {
        name: "PlaylistUtemLinks",
        props:['videoUrl', 'audioUrl']
    }
</script>