<template>        
  <li class="ml-stack-nav__item" v-if="lng">     
    <NavLink v-if="!lng.content.link.content.dataContent" :item="lng" :scount="0" />
    <div v-else data-js-module="overlay-handler">
      <a
        href="#"
        aria-haspopup="true"
        aria-controls="overlay"
        aria-expanded="false"
        data-js-item="overlay-handler-cta"
        :data-content="JSON.stringify(lng.content.link.content.dataContent)"
        class="ml-stack-nav__link"
      >
      <NavLinkContent :item="lng.content.link" :scount="0" />
      </a>
    </div>        
  </li>
</template>

<script>
import NavLink from "./NavLink.vue";
import NavLinkContent from "./NavLinkContent.vue";

export default {
	name: "NavLngSwitch",
	props: ["lng"],
	components: {
		NavLink,
		NavLinkContent
	}
};
</script>
