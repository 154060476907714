<template>
    <div data-css="c-error-page" class="error-page__content">
              <h2 class="error-page__headline">
                    Die Seite ist aktuell nicht verfügbar.
                </h2>
              <div class="error-page__description">
                    <p>
                        Wir arbeiten momentan an unserer Webseite der Ludwig-Maximilians-Universität München und deshalb kannst Du sie gerade nicht erreichen.
                        Versuche es in einigen Minuten noch einmal.
                    </p>
                    <p>
                        <slot></slot>
                    </p>
                </div>
            </div>
</template>

<script>

export default {
    name : "PlaylistError"
}

</script>