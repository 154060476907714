<template>
    <template v-for="(ed, index) in currentItem.creators">
        <template v-if="ed.name">
            <span>{{ ed.name.family }}, {{ ed.name.given }}<template v-if="index != currentItem.creators.length - 1">;
                </template></span>
        </template>
    </template>
    <template v-if="!currentItem.creators">
        <template v-for="(ed, index) in currentItem.editors">
            <template v-if="ed.name">
                <span>{{ ed.name.family }}, {{ ed.name.given }}<template v-if="index != currentItem.editors.length - 1">;
                    </template></span>
            </template>
        </template>
    </template>
</template>


<script>

export default {
    props: ["currentItem"]
}

</script>