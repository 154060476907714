<template>    
<ul class="ml-stack-nav__menu" >
      <li class="ml-stack-nav__item">   
        <span class="ml-stack-nav__load-init-loader">
        <span class="ml-stack-nav__load-init-loader-box is-one"></span>
        <span class="ml-stack-nav__load-init-loader-box is-two"></span>
        <span class="ml-stack-nav__load-init-loader-box is-three"></span>
    </span>        
      </li>
</ul>
    
</template>

<script>
export default {
	name: "NavLoading"
};
</script>

<style>
</style>
